<template>
<div class="bj" @click="toOtherpage">

</div>
</template>
<script>
	import {
		loginUser,getlogin,log_add
	} from "@/api/auth/index" //登录
export default {
    created() {
        this.login("hero")
    },
    methods: {
        toOtherpage() {
				this.$router.push({
					path: "/userotherPage_zt"
				});
			},
			// 登录
			login(user) {
				getlogin({
					account:user
				}).then(res=>{
			        console.log(res,"用户名密码")
					if(res){
						console.log(132)
					this.username=res.a
					this.password=res.p
					loginUser({
						account: this.username,
						password:this.password,
					})
					.then(res => {
						console.log("1111",res)
						if (res.info.code == 500) {
							return this.$message.error(res.info.msg);
						} else {
							localStorage.setItem("UserInfo", JSON.stringify(res.msg))
							localStorage.setItem("authInfo", JSON.stringify(res.auth))
						}
					})}
					else{
						return this.$message.error("登录异常，请联系管理员进行解决");	
					}
				})
		

			},
        }
}

</script>
<style lang="scss">
.bj{
// border: 1px solid #ffffff;
  margin-top:0vh;  
  left: 0;  
  width:100vw;  
  height:90vh;  

  /* 背景图片设置 */  
  background-image: url('~@/assets/images/yjdt.png');  
  /* 确保背景图片完全覆盖div */  
  background-size: 100% 100%;  
//   background-size: cover;    
  /* 可选：如果需要其他内容（如文本）在 div 上可见 */  
  z-index: -1; /* 将 div 置于其他内容之下 */  
}
</style>